(function () {
	const cookieDesc =
		Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') ||
		Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie');
	if (cookieDesc && cookieDesc.configurable) {
		Object.defineProperty(document, 'cookie', {
			enumerable: cookieDesc.enumerable,
			configurable: false,
			get() {
				return cookieDesc.get.call(document);
			},
			set(val) {
				if (/^.*\.qrator\.(net|cz)$/.test(location.hostname) && (/;domain=\.?qrator\.(net|cz)/i).test(val)) {
					return;
				}
				cookieDesc.set.call(document, val.replace(/^(..*);domain=.*qrator\.(net|cz)(.*)/i, '$1$2'));
			}
		});
	}
})();

